import React from "react";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import DashboardService from "../../services/dashboard.service";
import { useEffect } from "react";
import moment from "moment";

const Profits = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [profit, setProfit] = useState();
  const [loadingData, setLoadingData] = useState(true);


  const getProfitData = async () => {
    try {
      const response = await DashboardService.getProfit(currentPage, pageSize);
      setData(response.data.data);
      setTotalItems(response.data.totalCount);
      setProfit(response.data.totalProfit);
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      console.log("Error getting profit", e);
    }
  };
  useEffect(() => {
    getProfitData();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      name: <b>TRANSACTION ID</b>,
      selector: (row) => [row.transactionId],
      sortable: true,
      cell: (row) => <div>{row.transactionId}</div>,
    },
    {
      name: <b>USER NAME</b>,
      selector: (row) => [row.userId.userName],
      sortable: true,
      cell: (row) => <div>{row.userId.userName}</div>,
    },
    {
      name: <b>BUSINESS NAME</b>,
      selector: (row) => [row.businessName],
      sortable: true,
      cell: (row) => <div>{row.businessName}</div>,
    },
    // {
    //   name: <b>TRANSACTION TYPE</b>,
    //   selector: (row) => [row.type],
    //   sortable: true,
    //   cell: (row) => (
    //     <div style={{ color: row.type === "Deposit" ? "green" : "red" }}>
    //       {row.type === "Deposit" ? (
    //         <i
    //           className="mdi mdi-arrow-bottom-left"
    //           style={{ color: "green" }}
    //         ></i>
    //       ) : (
    //         <i className="mdi mdi-arrow-top-right" style={{ color: "red" }}></i>
    //       )}
    //       {row.type}
    //     </div>
    //   ),
    // },
    {
      name: <b>AMOUNT</b>,
      selector: (row) => [row.amount],
      sortable: true,
      cell: (row) => <div>{row.amount}</div>,
      //   <Moment format="DD-MM-YYYY">{row.createdat}</Moment>
    },
    // {
    //   name: <b>STATUS</b>,
    //   selector: (row) => [row.status],
    //   sortable: true,
    //   cell: (row) => (
    //     <div>
    //       <Button variant="success" size="sm">
    //         {row.status}
    //       </Button>
    //     </div>
    //   ),
    //   //   <Moment format="DD-MM-YYYY">{row.createdat}</Moment>
    // },
    {
      name: <b>TRANSACTION DATE</b>,
      selector: (row) => [row.createdAt],
      sortable: true,
      cell: (row) => <div>{moment(row.createdAt).format("DD/MM/YYYY")}</div>,
      //   <Moment format="DD-MM-YYYY">{row.createdat}</Moment>
    },
  ];
  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <Col sm={12} md={6} lg={6} xl={3}>
              <Card className="card custom-card mb-4">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-stats-up fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Profit
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                    Total Audio calls
                  </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {/* {data.totalAudioCall} */}
                          {profit}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={4} className="ms-auto mt-auto">
              <div
                className="custom-card text-end  pointer"
                style={{ marginRight: "18px" }}
              >
                {" "}
                {/* <Link
              className="btn btn-primary text-white"
              //  to={`/users/adduser/`}
            //   onClick={() => handleOpen(null, false)}
            >
              <i className="fas fa-plus"></i> &nbsp; Add User Details
            </Link> */}
              </div>
            </Col>
          </div>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={12} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-4">
                      Total Profit detail
                    </h6>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center">
                      {/* <input
                    style={{
                      borderRadius: "5px",
                      padding: "5px",
                      border: "1px solid #e8e8f7",
                    }}
                    // value={filterValue}
                    // onChange={handleFilterChange}
                    placeholder="Search here"
                    className="ms-auto mb-2 border-rounded"
                  /> */}
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                      // customStyles={{
                      //   rows: {
                      //     style: customRowStyles,
                      //   },
                      // }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Profits;
