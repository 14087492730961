import { useSnackbar } from "notistack";
import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const UserStatus = ({ show, onHide, statusFormik, userStatus, loading }) => {
  const statusClass = (status) => {
    switch (status) {
      case "true":
        return "tx-success";
      case "false":
        return "tx-danger";
      default:
        return "";
    }
  };

  const statusAll = statusClass(userStatus);
  return (
    <Modal centered show={show} backdrop="static" keyboard={false}>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title style={{ fontSize: "20px" }}>
          {userStatus === "true" && "Active"}
          {userStatus === "false" && "InActive"} User
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={statusFormik.handleSubmit}>
        <Modal.Body className="text-center">
          <>
            <i
              className={`${
                userStatus === "true" ? "fas fa-user" : "fas fa-user-slash"
              } ${statusAll} mb-3`}
              style={{ fontSize: "70px" }}
            ></i>
            <p className="mg-b-20 mg-x-20 ">
              Are you sure want to{" "}
              <b> {userStatus === "true" ? "Active" : "InActive"} User ?</b>
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Button
              disabled
              variant={
                userStatus === "true"
                  ? "success"
                  : userStatus === "false"
                  ? "danger"
                  : ""
              }
            >
              <Spinner
                as="span"
                aria-hidden="true"
                animation="border"
                role="status"
                style={{ color: "white", width: "24px", height: "24px" }}
                size="sm"
              />
            </Button>
          ) : (
            <Button
              variant={userStatus === "true" ? "success" : "danger"}
              type="submit"
            >
              {userStatus === "true" ? "Active" : "InActive"}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UserStatus;
