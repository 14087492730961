import React from "react";
import { Modal } from "react-bootstrap";
import LocationMap from "./LocationMap";
import { useState } from "react";

const MapModal = ({
  show,
  onHide,
  loctionCoordinates,
  setLocationCoordinates,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="fullscreen"
      //   centered
      //   style={{ maxWidth: "100%" }}
    >
      <Modal.Header closeButton onClick={() => loctionCoordinates}>
        <Modal.Title>Map</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocationMap
          setLocationCoordinates={setLocationCoordinates}
          loctionCoordinates={loctionCoordinates}
        />
      </Modal.Body>
    </Modal>
  );
};

export default MapModal;
