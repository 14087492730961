import { Select } from "antd";
import React, { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UseDialogState from "../../../hooks/UseDialogState";
import ViewUserList from "./ViewUserList";
import AddUserList from "./AddUserList";
import DeleteUserList from "./DeleteUserList";
import { Link, useNavigate } from "react-router-dom";
import EditQuery from "./EditQuery";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import UserStatus from "./UserStatus";
import UserListSetvice from "../../services/userList.service";
import moment from "moment";
import User from "../../../assets/img/users/user.png";

const UserList = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
    viewOpen,
    handleClickViewOpen,
    handleClickViewClose,
    userStatusOpen,
    handleUserStatusOpen,
    handleUserStatusClose,
  } = UseDialogState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [passDisable, setPassDisable] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [deleteId, setDeleteId] = useState("");
  const [isEmail, setIsEmail] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [isDefault, setIsDefault] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const customRowStyles = {
    gap: "10px",
    // padding:"0 20px"
  };

  const getAllUserData = async (search) => {
    try {
      setLoading(true);
      const response = await UserListSetvice.getAllUser(
        currentPage,
        pageSize,
        search
      );
      setData(response.data.data.users);
      setTotalItems(response.data.data.totalUsers);
      setLoadingData(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setLoadingData(false);
      console.log("Error all user data", e);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setIsEmail(selectedOption.value === "email" ? true : false);
    setIsMobile(selectedOption.value === "mobileNumber" ? true : false);
    setIsDefault(selectedOption.value);
  };
  const options = [
    { value: "email", label: "Email" },
    { value: "mobileNumber", label: "Phone Number" },
  ];

  useEffect(() => {
    getAllUserData(searchData);
  }, [currentPage, pageSize, searchData]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteUserData = async () => {
    try {
      const response = await UserListSetvice.deleteUser(deleteId);
      getAllUserData(searchData);
      handleClickDeleteClose();
      enqueueSnackbar("Data Delete Successfully!", { variant: "error" });
    } catch (e) {
      console.log("delete user err", e);
    }
  };

  const handleClickDeleteOpenDialog = (data) => {
    handleClickDeleteOpen();
    setDeleteId(data._id);
  };

  const handleAddUserNavigate = (row) => {
    navigate();
  };
  const handleViewUserNavigate = (row) => {
    navigate(`/users/view/${row._id}`, {
      state: {
        id: row._id,
      },
    });
  };

  // const handleNavigate = (category) => {
  //   navigate(`/category/${category.id}`, {
  //     state: {
  //       id: category.id,
  //       name: category.name,
  //     },
  //   });
  // };

  const { Option } = Select;

  const handleChange = (value, row) => {
    handleUserStatusOpen();
  };

  const validationSchema = Yup.object({
    // id: edit ? "" : Yup.string(),
    firstName: edit ? "" : Yup.string().required("First name is required"),
    lastName: edit ? "" : Yup.string().required("Last name is required"),
    email: isEmail
      ? Yup.string()
          .email("Invalid email address")
          .required("Email is required")
      : "",
    password: isEmail ? Yup.string().required("Password is required") : "",
    birthdate: edit ? "" : Yup.string().required("dob is required"),
    // profileImage: edit
    //   ? ""
    //   : Yup.string().required("Profile image is required"),
    mobileNumber: isMobile
      ? Yup.number().required("Phone number is required")
      : "",
  });

  const submit = async (values) => {
    const formData = new FormData();
    if (!values._id) {
      try {
        setLoading(true);
        formData.append("firstName", values.firstName);
        formData.append("lastName", values.lastName);
        formData.append("email", values.email);
        formData.append("birthdate", values.birthdate);
        formData.append("mobileNumber", values.mobileNumber);
        formData.append("profileImage", values.profileImage);
        const response = await UserListSetvice.addUserDetail(formData);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Data Add Successfully!", { variant: "success" });
          getAllUserData(searchData);
          handleClickClose();
        } else {
          enqueueSnackbar("User already exists by mobilenumber or email", {
            variant: "error",
          });
        }
        formik.resetForm();
        setLoading(false);
        setSelectedOption(null);
      } catch (e) {
        setLoading(false);
        enqueueSnackbar("User already exists by mobilenumber or email", {
          variant: "error",
        });
        console.log("Failed to submit add", e);
      }
      setIsEmail(false);
      // setIsDefault("")
    } else {
      try {
        setLoading(true);
        formData.append("userId", values._id);
        formData.append("firstName", values.firstName);
        formData.append("lastName", values.lastName);
        formData.append("email", values.email);
        formData.append("birthdate", values.birthdate);
        formData.append("mobileNumber", values.mobileNumber);
        formData.append("profileImage", values.profileImage);
        const response = await UserListSetvice.updateUserDetail(formData);
        setIsEmail(false);
        setIsDefault("");
        setSelectedOption(null);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Data Update Successfully!", { variant: "success" });
          getAllUserData(searchData);
          handleClickClose();
        } else {
          enqueueSnackbar("User already exists by mobilenumber or email", {
            variant: "error",
          });
        }
        formik.resetForm();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        enqueueSnackbar("User already exists by mobilenumber or email", {
          variant: "error",
        });
        console.log("Failed to submit update", e);
      }
    }
    // const successMessage = edit
    //   ? "Data Update Successfully!"
    //   : "Data Add Successfully!";
    // enqueueSnackbar(successMessage, { variant: "success" });
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      birthdate: "",
      profileImage: "",
      mobileNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  const handleOpen = (row) => {
    handleClickOpen();
    setPassDisable(!row);
    if (row && row._id) {
      setIsEmail(false);
      setIsMobile(false);
      setEdit(true);
      formik.setFieldValue("_id", row._id);
      formik.setFieldValue("firstName", row.firstName);
      formik.setFieldValue("lastName", row.lastName);
      formik.setFieldValue("email", row.email);
      formik.setFieldValue("password", row.password);
      formik.setFieldValue("birthdate", row.birthdate);
      formik.setFieldValue("profileImage", row.profileImage);
      formik.setFieldValue("mobileNumber", row.mobileNumber);
      if (row.email) {
        setSelectedOption(options.find((opt) => opt.value === "email"));
      } else if (row.mobileNumber) {
        setSelectedOption(options.find((opt) => opt.value === "mobileNumber"));
      }
    } else {
      setEdit(false);
      // formik.setFieldValue("id", "");
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
      formik.setFieldValue("birthdate", "");
      formik.setFieldValue("profileImage", "");
      formik.setFieldValue("mobileNumber", "");
      formik.resetForm();
    }
  };

  const handleStatusClose = () => {
    handleUserStatusClose();
  };

  const statusSubmit = async (values) => {
    const { id } = values;
    try {
      const response = await UserListSetvice.changeUserStatus(id, userStatus);
      getAllUserData(searchData);
      statusFormik.resetForm();
      handleStatusClose();
      enqueueSnackbar("Status Change Successfully", { variant: "success" });
    } catch (e) {
      console.log("Status Change error", e);
    }
  };

  const statusFormik = useFormik({
    initialValues: {
      id: "",
    },
    onSubmit: statusSubmit,
  });

  const ChangeEntryStatus = (status, data) => {
    setUserStatus(status);
    handleUserStatusOpen();
    statusFormik.setFieldValue("id", data._id);
  };

  const option = [
    { label: "Active", value: true },
    { label: "InActive", value: false },
  ];
  const optionIn = [{ label: "InActive", value: false }];

  const columns = [
    {
      name: <b>FIRST NAME</b>,
      selector: (row) => [row.firstName],
      sortable: true,
      cell: (row) => <div>{row.firstName}</div>,
    },
    {
      name: <b>LAST NAME</b>,
      selector: (row) => [row.lastName],
      sortable: true,
      cell: (row) => <div>{row.lastName}</div>,
    },
    {
      name: <b>E-MAIL</b>,
      selector: (row) => [row.email],
      sortable: true,
      cell: (row) => <div>{row.email}</div>,
    },
    {
      name: <b>D-O-B</b>,
      selector: (row) => [row.birthdate],
      sortable: true,
      // cell: (row) => <div>{row.birthdate}</div>,
      cell: (row) => <div>{row.birthdate}</div>,
    },
    {
      name: <b>PROFILE</b>,
      selector: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="demo-avatar-group my-auto float-end">
              <div className="main-img-user">
                {row.profileImage ? (
                  <img
                    className="rounded-circle avatar-md me-2"
                    src={row.profileImage}
                    width="30px"
                    height="30px"
                    alt=""
                  />
                ) : (
                  <img
                    className="rounded-circle avatar-md me-2"
                    src={User}
                    width="30px"
                    height="30px"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: <b>PHONE NUMBER</b>,
      selector: (row) => [row.mobileNumber],
      cell: (row) => <div>{row.mobileNumber}</div>,
      sortable: true,
    },
    {
      name: <b>STATUS</b>,
      selector: (row) => [row.isActive],
      cell: (row) => (
        <div className="d-flex my-auto">
          {row.isActive === true && (
            <div className="select-active">
              <Select
                value={row.isActive === true ? "Active" : "InActive"}
                onChange={(status) => ChangeEntryStatus(status, row)}
                style={{ width: "110px" }}
              >
                <option
                  value="Active"
                  className="text-white"
                  style={{
                    backgroundColor: "#19b159",
                    display: row.isActive === true ? "none" : "block",
                  }}
                >
                  <i className="fa fa-user"></i> Active
                </option>
                <option
                  value="false"
                  className="text-white"
                  style={{
                    backgroundColor: "#fd6074",
                    display: row.isActive === false ? "none" : "block",
                  }}
                >
                  <i className="fas fa-user-slash"></i> InActive
                </option>
              </Select>
            </div>
          )}
          {row.isActive === false && (
            <div className="select-inactive">
              <Select
                value={row.isActive === true ? "Active" : "InActive"}
                onChange={(status) => ChangeEntryStatus(status, row)}
                style={{ width: "110px" }}
              >
                <option
                  value="true"
                  className="text-white"
                  style={{
                    backgroundColor: "#19b159",
                    display: row.isActive === true ? "none" : "block",
                  }}
                >
                  <i className="fas fa-user"></i> Active
                </option>
                <option
                  value="InActive"
                  className="text-white"
                  style={{
                    backgroundColor: "#19b159",
                    display: row.isActive === false ? "none" : "block",
                  }}
                >
                  <i className="fas fa-user-slash"></i> InActive
                </option>
              </Select>
            </div>
          )}
        </div>
      ),
      sortable: true,
    },

    {
      name: <b>ACTION</b>,
      selector: (row) => [row.ACTIONS],
      width: "100px",
      sortable: true,
      cell: (row) => (
        <>
          <Button
            variant="info"
            size="sm"
            className="me-1"
            onClick={() => handleViewUserNavigate(row)}
          >
            <i className="fas fa-eye"></i>
          </Button>
          {/* <Link
            // to={`/users/adduser/`}
            // state={{
            //   id: row.id,
            //   update: true,
            //   userData: row,
            // }}
            variant="primary"
            size="sm"
            className="me-1 btn btn-primary btn-sm text-white"
            onClick={() => handleOpen(row, true)}
          >
            <i className="fa fa-pencil"></i>
          </Link> */}
          <Button
            variant="danger"
            size="sm"
            className="me-1"
            onClick={() => {
              handleClickDeleteOpenDialog(row);
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
    },
  ];

  const dateOnChange = (date) => {
    formik.setFieldValue(
      "birthdate",
      moment(date.target.value).format("DD/MM/YYYY")
    );
  };

  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <Col sm={12} md={6} lg={6} xl={3}>
              <Card className="card custom-card mb-4">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="mdi mdi-account-multiple fs-30"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Users
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total Audio calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {/* {data.totalAudioCall} */}
                          {totalItems}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={4} className="ms-auto mt-auto">
              <div
                className="custom-card text-end  pointer mb-4"
                style={{ marginRight: "18px" }}
              >
                {" "}
                {/* <Link
                  className="btn btn-primary text-white"
                  //  to={`/users/adduser/`}
                  onClick={() => {
                    handleOpen(null, false);
                    setPassDisable(true);
                  }}
                >
                  <i className="fas fa-plus"></i> &nbsp; Add User Details
                </Link> */}
                <AddUserList
                  show={open}
                  onHide={handleClickClose}
                  formik={formik}
                  edit={edit}
                  passDisable={passDisable}
                  setIsEmail={setIsEmail}
                  setIsMobile={setIsMobile}
                  handleSelectChange={handleSelectChange}
                  selectedOption={selectedOption}
                  isDefault={isDefault}
                  dateOnChange={dateOnChange}
                  options={options}
                  loading={loading}
                  setIsDefault={setIsDefault}
                  setSelectedOption={setSelectedOption}
                  // referrealCoe={referrealCoe}
                />
              </div>
            </Col>
          </div>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={12} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-1">
                      all users detail
                    </h6>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center">
                      <input
                        style={{
                          borderRadius: "5px",
                          padding: "5px",
                          border: "1px solid #e8e8f7",
                        }}
                        value={searchData}
                        onChange={(e) => setSearchData(e.target.value)}
                        placeholder="Search here"
                        className="ms-auto mb-2 border-rounded"
                      />
                    </div>
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      // style={{
                      //   fontSize: "20px",
                      //   fontWeight: "bold",
                      // }}
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                      highlightOnHover
                      customStyles={{
                        rows: {
                          style: customRowStyles,
                        },
                      }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <ViewUserList show={viewOpen} onHide={handleClickViewClose} /> */}
          <DeleteUserList
            show={deleteOpen}
            onHide={handleClickDeleteClose}
            path={"/users"}
            onDelete={deleteUserData}
            loading={loading}
          />
          <UserStatus
            show={userStatusOpen}
            statusFormik={statusFormik}
            onHide={handleStatusClose}
            userStatus={userStatus}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default UserList;
