import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DashboardService from "../../services/dashboard.service";
import { useEffect } from "react";
import { useState } from "react";

const TrendingSearch = () => {
  const customRowStyles = {
    gap: "10px",
    // padding:"0 20px"
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [loadingData, setLoadingData] = useState(true);

  const trendingSearchData = async () => {
    try {
      const response = await DashboardService.trendingSearch(
        currentPage,
        pageSize
      );
      setData(response.data.data);
      setTotalItems(response.data.totalTredindSearch);
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      console.log("Error", e);
    }
  };

  useEffect(() => {
    trendingSearchData();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      name: <b>SEARCH TEXT</b>,
      selector: (row) => [row.searchText],
      sortable: true,
      cell: (row) => <div>{row.searchText}</div>,
    },
  ];
  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <div>
              {/* <h2 className="main-content-title tx-24 mb-3 mt-2">
            TRENDING SEARCH
          </h2> */}
            </div>
          </div>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={12} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-4">
                      all Trending Search List
                    </h6>
                  </div>
                  <div className="responsive">
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      customStyles={{
                        rows: {
                          style: customRowStyles,
                        },
                      }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TrendingSearch;
