import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import {
  Accordion,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import UseDialogState from "../../../hooks/UseDialogState";
import EditQuery from "./EditQuery";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useEffect } from "react";
import DeleteUserList from "./DeleteUserList";
import DeleteQueryAns from "./DeleteQueryAns";
import Select from "react-select";
import { IoArrowBack } from "react-icons/io5";
import DataTable from "react-data-table-component";
import UserListSetvice from "../../services/userList.service";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import UpdateQueryService from "../../services/query.service";
import DeleteAns from "./DeleteAns";
import { CircularProgress } from "@mui/material";
import User from "../../../assets/img/users/user.png";

const ViewUserList = (props) => {
  const {
    editQuery: modalShow,
    handleEditQueryOpen: handleDialogOpen,
    handleEditQueryClose: handleDialogClose,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
    deleteQueryOpen,
    handleClickDeleteQueryOpen,
    handleClickDeleteQueryClose,
  } = UseDialogState();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedAccordions, setSelectedAccordions] = useState(["0"]);
  const [updateQuery, setUpdateQuery] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const Location = useLocation();
  const ViewDataId = Location?.state?.id;
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [viewAll, setviewAll] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState([]);
  const [queAns, setQueAns] = useState("");
  const [truncateDescription, setTruncateDescription] = useState(true);
  const [queryMore, setQueryMore] = useState(true);
  const [deleteAns, setdeleteAns] = useState("");
  const [deleteQue, setdeleteQue] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [isPassChange, setIsPassChange] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passUpdate, setPassUpdate] = useState("******");
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setcountryCode] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [userStatus, setUserStatus] = useState("");

  const toggleDescription = (row) => {
    setTruncateDescription((prev) => ({
      ...prev,
      [row._id]: !prev[row._id],
    }));
  };

  const handleQureyMore = (data) => {
    setQueryMore((prev) => ({
      ...prev,
      [data.id]: !prev[data.id],
    }));
  };

  const viewUserDetailData = async () => {
    try {
      setLoading(true);
      const response = await UserListSetvice.viewUserDetail(ViewDataId);
      const result = response.data.data.user;
      formik.setFieldValue("firstName", result.firstName);
      formik.setFieldValue("lastName", result.lastName);
      formik.setFieldValue("email", result.email);
      formik.setFieldValue(
        "birthdate",
        moment(result.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      formik.setFieldValue("mobileNumber", result.mobileNumber);
      formik.setFieldValue("countryCode", result.countryCode);
      formik.setFieldValue("profileImage", result.profileImage);
      formik.setFieldValue("isActive", result.isActive);
      // if (result.email) {
      //   setSelectedOption({ value: "email", label: "Email" });
      // } else if (result.mobileNumber) {
      //   setSelectedOption({ value: "mobileNumber", label: "Phone Number" });
      // }
      setcountryCode(result.countryCode);
      setData(response.data.data.reservation);
      setQuery(response.data.data.getAllQuary);
      setLoadingData(false);
      setIsLoadingUser(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setLoadingData(false);
      console.log("Error in user view", e);
    }
  };

  useEffect(() => {
    viewUserDetailData();
  }, []);

  const handleChangeDate = (date) => {
    formik.setFieldValue("birthdate", date.target.value);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handleToggleEditMode = (row) => {
    if (row === true) {
      setIsLoadingUser(true);
      viewUserDetailData();
      const fileInput = document.getElementById(`fileInput`);
      if (fileInput) {
        fileInput.value = "";
      }
    }
    setIsEdit(!isEdit);
    setIsPassChange(false);
    setShowPassword(false);
    setPassUpdate("******");
    setPreviewImage("");
  };

  const handleAccordionToggle = (eventKey) => {
    const updatedAccordions = selectedAccordions.includes(eventKey)
      ? selectedAccordions.filter((key) => key !== eventKey)
      : [...selectedAccordions, eventKey];

    setSelectedAccordions(updatedAccordions);
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const trendingQueries = ["Finance", "Marketing", "Sales", "Investment"];

  const handleOpen = (data, index, i) => {
    setEditOpen(true);
    handleDialogOpen();
    setUpdateQuery(data);
    if (data === "q") {
      setQueAns(true);
      queryFormik.setFieldValue("question", query[index].question.question);
      queryFormik.setFieldValue("questionId", query[index].question._id);
    } else {
      setQueAns(false);
      queryFormik.setFieldValue({
        ...queryFormik.values,
        answer: queryFormik.setFieldValue(
          "answer",
          query[index].answers[i].answer
        ),
      });
      queryFormik.setFieldValue("answerId", query[index].answers[i]._id);
    }
  };

  const submitQuery = async (values) => {
    const { questionId, question, answerId, answer } = values;
    if (queAns) {
      try {
        setLoading(true);
        const response = await UpdateQueryService.updateQueryQue(
          questionId,
          question
        );
        handleDialogClose();
        setEditOpen(false);
        viewUserDetailData();
        enqueueSnackbar("Data Update Successfully!", { variant: "success" });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log("Error submitting update query que", e);
      }
    } else {
      try {
        setLoading(true);
        const response = await UpdateQueryService.updateQueryAns(
          answerId,
          answer
        );
        handleDialogClose();
        setEditOpen(false);
        viewUserDetailData();
        enqueueSnackbar("Data Update Successfully!", { variant: "success" });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log("Error submitting update query", e);
      }
    }
    // }
    // (values) => {
    //   console.log("Form submitted with values:", values);

    //   enqueueSnackbar("Data Update Successfully!", { variant: "success" });
    //   handleDialogClose();
    //   setEditOpen(false);
    // }
  };

  const queryFormik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    onSubmit: submitQuery,
  });

  const deleteQueryAnsData = async () => {
    try {
      const response = await UpdateQueryService.deleteQueryAns(deleteAns);
      handleClickDeleteClose();
      viewUserDetailData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "success" });
    } catch (e) {
      console.log("Error deleting query", e);
    }
  };

  const handleClickDeleteOpenDialog = (data) => {
    handleClickDeleteOpen();
    setdeleteAns(data);
  };

  const deleteQueryQuesData = async () => {
    try {
      const response = await UpdateQueryService.deleteQueryQue(deleteQue);
      handleClickDeleteQueryClose();
      viewUserDetailData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "success" });
    } catch (e) {
      console.log("Error deleting query", e);
    }
  };

  const handleClickDeleteOpenDialogQue = (data) => {
    handleClickDeleteQueryOpen();
    setdeleteQue(data.question._id);
  };

  const submit = async (values) => {
    const formData = new FormData();
    setIsEdit(false);

    try {
      const response = await UserListSetvice.changeUserStatus(
        ViewDataId,
        values.isActive
      );
      viewUserDetailData();
      formik.resetForm();
      enqueueSnackbar("Status Change Successfully", { variant: "success" });
    } catch (e) {
      console.log("Status Change error", e);
    }
    try {
      setIsLoadingUser(true);
      // if (!ViewDataId) {
      formData.append("userId", ViewDataId);
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append(
        "birthdate",
        moment(values.birthdate).format("DD/MM/YYYY")
      );
      formData.append("mobileNumber", values.mobileNumber);
      formData.append("countryCode", values.countryCode);
      formData.append("profileImage", values.profileImage);
      formData.append("isActive", values.isActive);
      const response = await UserListSetvice.updateUserDetail(formData);
      formik.resetForm();
      viewUserDetailData();

      enqueueSnackbar("Data Update Successfully!", { variant: "success" });
      // }
    } catch (err) {
      setIsLoadingUser(false);
      console.log("Form submitted update err", err);
    }
    if (passUpdate !== "******") {
      try {
        const response = await UserListSetvice.changePassword(
          ViewDataId,
          passUpdate
        );
        enqueueSnackbar("Password change sucessfully!", { variant: "success" });
        setIsPassChange(false);
        setShowPassword(false);
        // setPassUpdate("******");
      } catch (err) {
        console.log("Form submitted update err", err);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: "",
      firstName: "",
      lastName: "",
      email: "",
      birthdate: "",
      feedback: "",
      mobileNumber: "",
      status: "",
      profileImage: "",
      passowrd: "",
      countryCode: "",
      isActive: true,
    },
    onSubmit: submit,
  });

  const ChangeEntryStatus = (status, data) => {
    setUserStatus(status);
    formik.setFieldValue("userId", data.id);
    formik.setFieldValue("isActive", status);
  };

  const handleBack = () => {
    navigate("/users");
  };

  const customRowStyles = {
    gap: "10px",
    // padding:"0 20px"
  };
  const columns = [
    {
      name: <b>TOTAL NUMBER OF PEOPLE</b>,
      selector: (row) => [row.numberOfPeople],
      sortable: true,
      cell: (row) => <div>{row.numberOfPeople}</div>,
    },
    {
      name: <b>BUSINESS NAME</b>,
      selector: (row) => [row.bussinessId.bussinessName],
      sortable: true,
      cell: (row) => <div>{row.bussinessId.bussinessName}</div>,
    },
    {
      name: <b>BOOKING TIME</b>,
      selector: (row) => [row.startTime - row.endTime],
      sortable: true,
      cell: (row) => (
        <div>
          {moment(row.startTime).format("hh:mm A")} -{" "}
          {moment(row.endTime).format("hh:mm A")}
        </div>
      ),
    },
    {
      name: <b>BOOKING DATE</b>,
      selector: (row) => [row.date],
      sortable: true,
      cell: (row) => <div>{moment(row.date).format("DD/MM/YYYY")}</div>,
      //   <Moment format="DD-MM-YYYY">{row.createdat}</Moment>
    },
    {
      name: <b>STATUS</b>,
      sortable: true,
      cell: (row) => (
        <div>
          <Button
            variant={
              row.status === "Pending"
                ? "warning"
                : row.status === "Accepted"
                ? "success"
                : "danger"
            }
            size="sm"
            style={{ cursor: "default" }}
          >
            {row.status}
            {/* Pending */}
          </Button>
        </div>
      ),
    },
  ];

  const handlePasswordChange = (e) => {
    setIsPassChange(true);
    setPassUpdate(e.target.value);
  };

  const handlePasswordFieldClick = () => {
    if (isEdit) {
      setPassUpdate("");
      formik.setFieldValue("password", "");
    }
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("profileImage", file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const option = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "InActive",
      value: false,
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text && text.length <= maxLength) {
      return text;
    } else {
      return text && text.substr(0, maxLength) + "...";
    }
  };

  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="page-header">
            <div className="me-auto">
              <Breadcrumb>
                <BreadcrumbItem
                  className="mb-2"
                  onClick={() => handleBack()}
                  active
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "#170C6B",
                  }}
                >
                  <IoArrowBack /> Back
                </BreadcrumbItem>
              </Breadcrumb>
              <h2 className="main-content-title tx-24 mg-b-5">User Detail</h2>
            </div>
            {/* </Col> */}
            {/* <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button onClick={() => navigate("/users")}>
            <i className="fa fa-close"></i>
          </Button>
        </div> */}
          </div>
          <Row className="row row-sm">
            <Col xl={6} lg={12} md={12}>
              <Card className="custom-card" style={{ position: "relative" }}>
                {isLoadingUser && (
                  <div
                    className="loader"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: "99999",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                <Card.Body>
                  <div
                    className="d-flex align-items-start pb-3 border-bottom"
                    style={{ paddingBottom: "16px" }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <h4>Profile Detail</h4>
                    </div>
                    {/* <img
                      src="https://images.pexels.com/photos/3772510/pexels-photo-3772510.jpeg?auto=compress&cs=tinysrgb&w=600"
                      className="img rounded-circle avatar-xl ms-auto"
                      alt="user1"
                    /> */}
                    <div className="ms-auto">
                      {isEdit ? (
                        <Button
                          onClick={() => {
                            handleToggleEditMode(true);
                          }}
                        >
                          <i className="fa fa-close"></i>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            handleToggleEditMode(false);
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>
                      )}
                    </div>
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    {/* {viewAll?.map(( , index) => ( */}
                    <div className="py-2">
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                // textAlign: "start",
                                color: "#000",
                                // marginTop: "15px",
                              }}
                            >
                              First Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              placeholder="First Name"
                              readOnly={!isEdit}
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Last Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              readOnly={!isEdit}
                              // value="Doe"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          {/* <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Label
                          style={{
                            color: "#000",
                          }}
                        >
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="E-mail"
                          // value="test@gmail.com"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          readOnly={!isEdit}
                        />
                      </Form.Group> */}
                          {/* {selectedOption &&
                            selectedOption.value === "email" && ( */}
                          <>
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Email Address
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="E-mail"
                                // value="test@gmail.com"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                readOnly={!isEdit}
                              />
                            </Form.Group>
                          </>
                          {/* )} */}
                          {/* {selectedOption &&
                            selectedOption.value === "mobileNumber" && ( */}
                          <>
                            {" "}
                            {/* <Form.Group
                                  controlid="validationFormik101"
                                  className="position-relative"
                                >
                                  <Form.Label
                                    style={{
                                      color: "#000",
                                    }}
                                  >
                                    Phone Number
                                  </Form.Label>
                                  <PhoneInput
                                    className="addContacts"
                                    country={"in"}
                                    value={formik.values.mobileNumber}
                                    onChange={(value) =>
                                      formik.setFieldValue("mobileNumber",  value.split(countryCode))
                                    }
                                    disabled={!isEdit}
                                  />
                                </Form.Group> */}
                          </>
                        </div>
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Phone Number
                            </Form.Label>
                            <PhoneInput
                              className="addContacts"
                              country={"in"}
                              value={
                                formik.values.countryCode +
                                formik.values.mobileNumber
                              }
                              onChange={(value, country) => {
                                formik.setFieldValue(
                                  "mobileNumber",
                                  value.split(country.dialCode)[1]
                                );
                                formik.setFieldValue(
                                  "countryCode",
                                  country.dialCode
                                );
                              }}
                              disabled={!isEdit}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Date of Birth
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="birthdate"
                              placeholder="D-O-B"
                              // value="01-01-2001"
                              readOnly={!isEdit}
                              value={formik.values.birthdate}
                              // max={
                              //   new Date(new Date().getFullYear() - 18, 0, 1)
                              //     .toISOString()
                              //     .split("T")[0]
                              // }
                              onChange={(e) => handleChangeDate(e)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Status
                            </Form.Label>
                            {!isEdit ? (
                              <Form.Control
                                type="text"
                                name="status"
                                placeholder="Status"
                                // value="Active"
                                value={
                                  formik.values.isActive === true
                                    ? "Active"
                                    : "InActive"
                                }
                                onChange={formik.handleChange}
                                readOnly={!isEdit}
                              />
                            ) : (
                              <>
                                {formik.values.isActive === true && (
                                  <Select
                                    value={{
                                      label:
                                        formik.values.isActive === true
                                          ? "Active"
                                          : "InActive",
                                      value:
                                        formik.values.isActive === true
                                          ? true
                                          : false,
                                    }}
                                    isSearchable={false}
                                    onChange={(selectedOption) => {
                                      ChangeEntryStatus(
                                        selectedOption.value,
                                        data
                                      );
                                    }}
                                    options={[
                                      {
                                        label: "InActive",
                                        value: false,
                                      },
                                    ]}
                                    isDisabled={!isEdit}
                                  />
                                )}
                                {formik.values.isActive === false && (
                                  <Select
                                    value={{
                                      label:
                                        formik.values.isActive === false
                                          ? "InActive"
                                          : "Active",
                                      value:
                                        formik.values.isActive === false
                                          ? false
                                          : true,
                                    }}
                                    isSearchable={false}
                                    onChange={(selectedOption) => {
                                      ChangeEntryStatus(
                                        selectedOption.value,
                                        data
                                      );
                                    }}
                                    options={[
                                      {
                                        label: "Active",
                                        value: true,
                                      },
                                    ]}
                                    isDisabled={!isEdit}
                                  />
                                )}
                              </>
                              // <Select
                              //   value={option.find(
                              //     (data) =>
                              //       data.value === formik.values.isActive
                              //   )}
                              //   isSearchable={false}
                              //   onChange={(selectedOption) => {
                              //     ChangeEntryStatus(selectedOption.value, data);
                              //   }}
                              //   options={option}
                              //   isDisabled={!isEdit}
                              // />
                            )}
                          </Form.Group>
                        </div>{" "}
                      </div>
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Profile
                            </Form.Label>
                            <Form.Control
                              id={`fileInput`}
                              type="file"
                              name="profileImage"
                              onChange={handleProfileChange}
                              disabled={!isEdit}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Password
                            </Form.Label>
                            <div className="position-relative">
                              <Form.Control
                                type={`${showPassword ? "text" : "password"}`}
                                name="password"
                                placeholder="password"
                                readOnly={!isEdit}
                                value={passUpdate}
                                onChange={(e) => handlePasswordChange(e)}
                                onClick={handlePasswordFieldClick}
                              />
                              {isPassChange && (
                                <i
                                  onClick={() => setShowPassword(!showPassword)}
                                  className={`fa ${
                                    showPassword ? "fa-eye-slash" : "fa-eye"
                                  } position-absolute text-dark`}
                                  style={{ top: 12, right: 15 }}
                                />
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="mt-3 col-md-6 pb-3">
                          {previewImage ? (
                            <img
                              src={previewImage}
                              alt="Selected profileImage"
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                            />
                          ) : (
                            <img
                              src={formik.values.profileImage}
                              alt="Current profileImage"
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                            />
                          )}
                        </div>
                        <div
                          className="col-md-6 pb-3"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "end",
                          }}
                        >
                          {isEdit ? (
                            <button
                              name="submit"
                              className="btn btn-primary"
                              type="submit"
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              name="submit"
                              className="btn btn-primary"
                              type="submit"
                              disabled
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* ))} */}
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={12} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-4">booking detail</h6>
                  </div>
                  <div className="responsive">
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      // customStyles={{
                      //   rows: {
                      //     style: customRowStyles,
                      //   },
                      // }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="row row-sm">
            <Col xl={12} lg={12} md={12}>
              {/* <Card className="custom-card">
              <Card.Body> */}
              <div style={{ marginTop: "10px" }}>
                <h4>Queries</h4>
              </div>
              <Accordion defaultActiveKey={selectedAccordions} alwaysOpen>
                {query.length > 0 ? (
                  query.length > 0 &&
                  query.map((querys, index) => (
                    // {querys index) => (
                    <Accordion.Item
                      key={index}
                      eventKey={!editOpen ? `q${index + 1}` : ""}
                    >
                      <Accordion.Header
                        style={{
                          backgroundColor: selectedAccordions.includes("0")
                            ? "#29295d"
                            : "#cfd9eb",
                        }}
                        onClick={() => handleAccordionToggle(`q${index + 1}`)}
                      >
                        <div className="custom-accordion-header justify-content-between d-flex align-items-center w-100">
                          <div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50px 1fr",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {querys.question.userId.profileImage ? (
                                <div className="Queries-imges">
                                  <img
                                    src={querys.question.userId.profileImage}
                                    className="img rounded-circle ms-auto"
                                  />
                                </div>
                              ) : (
                                <div className="Queries-imges">
                                  <img
                                    src={User}
                                    className="img rounded-circle ms-auto"
                                  />
                                </div>
                              )}
                              <div>
                                <div
                                  className="name"
                                  style={{
                                    marginLeft: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {querys.question.userId.userName}
                                </div>
                                <div
                                  className="question"
                                  style={{
                                    marginTop: "10px",
                                    marginLeft: "15px",
                                  }}
                                >
                                  {truncateText(querys.question.question, 350)}
                                </div>
                                {/* <div
                                  className="profile-details"
                                  style={{ marginLeft: "15px" }}
                                >
                                  <span>
                                    {querys.question.question && (
                                      <span>
                                        {handleQureyMore[querys.id]
                                          ? querys.question.question
                                          : `${querys.question.question.slice(
                                              0,
                                              250
                                            )}`}
                                      </span>
                                    )}
                                    {querys.question.question &&
                                      querys.question.question.length > 250 && (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "bolder",
                                          }}
                                          onClick={() =>
                                            handleQureyMore(querys)
                                          }
                                        >
                                          {handleQureyMore[querys.id]
                                            ? " ...LESS"
                                            : " ...MORE"}
                                        </span>
                                      )}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              type="button"
                              variant="info"
                              className="me-2"
                              size="sm"
                              onClick={() => handleOpen("q", index)}
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                            <Button
                              variant="danger"
                              className="me-2"
                              size="sm"
                              onClick={() =>
                                handleClickDeleteOpenDialogQue(querys)
                              }
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </div>
                          {/* </div> */}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {querys.answers.map((ans, i) => (
                          <div
                            className="profile-container d-flex align-items-start justify-content-between"
                            key={i}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                // alignItems: "center",
                                marginLeft: "55px",
                                marginBottom: "15px",
                              }}
                            >
                              <div
                                style={{
                                  height: "45px",
                                  width: "100%",
                                  maxWidth: "45px",
                                  minWidth: "45px",
                                }}
                              >
                                {ans.userId?.profileImage ? (
                                  <img
                                    src={ans.userId?.profileImage}
                                    alt="Profile"
                                    className="img rounded-circle avatar-xl"
                                    // style={{ height: "45px", width: "45px" }}
                                  />
                                ) : (
                                  <img
                                    src={User}
                                    alt="Profile"
                                    className="img rounded-circle avatar-xl"
                                    // style={{ height: "45px", width: "45px" }}
                                  />
                                )}
                              </div>
                              <div>
                                <div
                                  style={{
                                    marginLeft: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {/* {querys.answers.map((ans, index) => ( */}
                                  <>{ans.userId.userName}</>
                                  {/* ))} */}
                                </div>
                                <div
                                  className="profile-details"
                                  style={{ marginLeft: "15px" }}
                                >
                                  <span>
                                    {ans.answer && (
                                      <span>
                                        {truncateDescription[ans._id]
                                          ? ans.answer
                                          : `${ans.answer.slice(0, 250)}`}
                                      </span>
                                    )}
                                    {ans.answer && ans.answer.length > 250 && (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "bolder",
                                        }}
                                        onClick={() => toggleDescription(ans)}
                                      >
                                        {truncateDescription[ans._id]
                                          ? "...LESS"
                                          : "...MORE"}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <Button
                                variant="info"
                                className="me-2"
                                size="sm"
                                onClick={() => handleOpen("a1", index, i)}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  handleClickDeleteOpenDialog(ans._id)
                                }
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    // ))}
                  ))
                ) : (
                  <div style={{ marginBottom: "25px" }}>Query Not Found</div>
                )}
              </Accordion>
              {/* </Card.Body>
            </Card> */}
            </Col>
          </Row>
          <EditQuery
            show={modalShow}
            onHide={handleDialogClose}
            updateQuery={updateQuery}
            formik={queryFormik}
            setEditOpen={setEditOpen}
            loading={loading}
          />
          {/* <DeleteUserList
        show={deleteOpen}
        onHide={handleClickDeleteClose}
        path={"/users/view/:id"}
        onDelete={deleteQueryAnsData}
      /> */}
          <DeleteQueryAns
            show={deleteQueryOpen}
            onHide={handleClickDeleteQueryClose}
            onDelete={deleteQueryQuesData}
            loading={loading}
          />
          <DeleteAns
            show={deleteOpen}
            onHide={handleClickDeleteClose}
            onDelete={deleteQueryAnsData}
            loading={loading}
          />
        </Fragment>
      )}
    </>
  );
};

export default ViewUserList;
