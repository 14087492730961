import React, { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import UseDialogState from "../../../hooks/UseDialogState";
import AddFeature from "./AddFeature";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import DeleteFeature from "./DeleteFeature";
import AddEcofriendly from "./AddEcofriendly";
import DeleteEcofriendly from "./DeleteEcofriendly";
import FeatureandEcofriendlyService from "../../services/featureandeco.service";
import { useSnackbar } from "notistack";

const FeatureandEcoFriendly = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
    openEco,
    handleClickOpenEco,
    handleClickCloseEco,
    DeleteOpenEco,
    handleClickDeleteOpenEco,
    handleClickDeleteCloseEco,
  } = UseDialogState();
  const [isEdit, setIsEdit] = useState(false);
  const [isEcoEdit, setisEcoEdit] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [deleteFeature, setDeleteFeature] = useState("");
  const [ecoData, setEcoData] = useState([]);
  const [ecoCurrentPage, setEcoCurrentPage] = useState(1);
  const [ecoTotalItem, setEcoTotalItem] = useState();
  const [ecoPageSize, setEcoPageSize] = useState(10);
  const [deleteEco, setDeleteEco] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingEco, setLoadingEco] = useState(false);
  const [isFeatureImage, setIsFeatureImage] = useState("");
  const [isEcoImage, setIsEcoImage] = useState("");

  const getFeatureData = async () => {
    try {
      setLoading(true);
      setLoadingEco(true);
      const response = await FeatureandEcofriendlyService.getFeature(
        currentPage,
        pageSize
      );
      setData(response.data.data);
      setTotalItems(response.totalFeatures);
      setLoadingData(false);
      setLoading(false);
      setLoadingEco(false);
    } catch (e) {
      setLoadingData(false);
      setLoading(false);
      setLoadingEco(false);
      console.log("Failed to get feature data", e);
    }
  };

  useEffect(() => {
    getFeatureData();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const submit = async (values) => {
    const { _id } = values;
    const formData = new FormData();
    if (_id === "") {
      try {
        setLoading(true);
        formData.append("name", values.name);
        formData.append("logo", values.logo);
        const response = await FeatureandEcofriendlyService.addFeature(
          formData
        );
        formik.resetForm();
        handleClickClose();
        getFeatureData();
        enqueueSnackbar("Data Add Successfully!", { variant: "success" });
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        formData.append("id", values._id);
        formData.append("name", values.name);
        formData.append("logo", values.logo);
        const response = await FeatureandEcofriendlyService.updateFeature(
          formData
        );
        handleClickClose();
        getFeatureData();
        setLoading(false);
        enqueueSnackbar("Data Update Successfully!", { variant: "success" });
      } catch (e) {
        setLoading(false);
        console.log("Failed to submit update", e);
      }
    }
  };

  const validationSchema = Yup.object({
    name: isEdit ? "" : Yup.string().required("Feature is Required"),
    logo: isEdit ? "" : Yup.string().required("Logo is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: "",
      name: "",
      logo: "",
    },
    onSubmit: submit,
    validationSchema,
  });

  const handleFeatureOpen = (featureData) => {
    handleClickOpen();
    if (featureData && featureData._id) {
      setIsEdit(true);
      formik.setFieldValue("_id", featureData._id);
      formik.setFieldValue("name", featureData.name);
      formik.setFieldValue("logo", featureData.logo);
    } else {
      setIsEdit(false);
      formik.setFieldValue("name", "");
      formik.setFieldValue("_id", "");
      formik.setFieldValue("logo", "");
    }
  };

  const handleFileChange = (file) => {
    const selectedFile = file.currentTarget.files[0];
    const imageUrl = URL.createObjectURL(selectedFile);
    setIsFeatureImage(imageUrl);
    formik.setFieldValue("logo", selectedFile);
  };

  const handleClickDeleteOpenDialog = (data) => {
    handleClickDeleteOpen();
    setDeleteFeature(data._id);
  };

  const deleteFeatureData = async () => {
    try {
      const response = await FeatureandEcofriendlyService.deleteFeature(
        deleteFeature
      );
      handleClickDeleteClose();
      getFeatureData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "error" });
    } catch (err) {
      console.log("delete feature", err);
    }
  };

  const columns = [
    {
      name: <b>FEATURE</b>,
      selector: (row) => [row.name],
      sortable: true,
      cell: (row) => <div>{row.name}</div>,
    },
    {
      name: <b>LOGO</b>,
      selector: (row) => [row.logo],
      sortable: true,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="demo-avatar-group my-auto float-end">
            <div className="main-img-user">
              <img
                className="rounded-circle avatar-md me-2"
                src={row.logo}
                width="30px"
                height="30px"
                alt=""
                style={{objectFit: "cover"}}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => [row.ACTIONS],
      // width: "100px",
      sortable: true,
      cell: (row) => (
        <>
          <Link
            // to={`/users/adduser/`}
            // state={{
            //   id: row.id,
            //   update: true,
            //   userData: row,
            // }}
            variant="primary"
            size="sm"
            className="me-1 btn btn-primary btn-sm text-white"
            onClick={() => handleFeatureOpen(row)}
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="me-1"
            onClick={() => {
              handleClickDeleteOpenDialog(row);
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
    },
  ];

  const getEcofriendlyData = async () => {
    try {
      const response = await FeatureandEcofriendlyService.getEcofriendly(
        ecoCurrentPage,
        ecoPageSize
      );
      setEcoData(response.data.data);
      setLoadingData(false);
      setEcoTotalItem(response.totalcount);
    } catch (e) {
      setLoadingData(false);
      console.log("get eco err", e);
    }
  };

  useEffect(() => {
    getEcofriendlyData();
  }, [ecoCurrentPage, ecoPageSize]);

  const handleRowsPerPageChangeEco = (newPageSize, currentPage) => {
    setEcoPageSize(newPageSize);
    setEcoCurrentPage(currentPage);
  };

  const handlePageChangeEco = (page) => {
    setEcoCurrentPage(page);
  };

  const EcoSubmit = async (values) => {
    const { _id, name } = values;
    const formData = new FormData();
    if (_id === "") {
      try {
        setLoadingEco(true);
        formData.append("name", values.name);
        formData.append("logo", values.logo);
        // const response = "";
        const response = await FeatureandEcofriendlyService.addEcofriendly(
          formData
        );
        handleClickCloseEco();
        getEcofriendlyData();
        ecoFormik.resetForm();
        enqueueSnackbar("Data Add Successfully!", { variant: "success" });
        setLoadingEco(false);
      } catch (e) {
        console.log("add eco error", e);
        setLoadingEco(false);
      }
    } else {
      try {
        setLoadingEco(true);
        formData.append("id", values._id);
        formData.append("name", values.name);
        formData.append("logo", values.logo);
        const response = await FeatureandEcofriendlyService.updateEcofriendly(
          formData
        );
        // const response = "";
        handleClickCloseEco();
        getEcofriendlyData();
        enqueueSnackbar("Data Update Successfully!", { variant: "success" });
        setLoadingEco(false);
        ecoFormik.resetForm();
      } catch (err) {
        console.log("err eco in update", err);
        setLoadingEco(false);
      }
    }
  };

  const ecoValidationSchema = Yup.object({
    name: isEcoEdit ? "" : Yup.string().required("Eco-Friendly is required"),
    logo: isEcoEdit ? "" : Yup.string().required("Logo is required"),
  });

  const ecoFormik = useFormik({
    initialValues: {
      _id: "",
      name: "",
    },
    onSubmit: EcoSubmit,
    validationSchema: ecoValidationSchema,
  });

  const handleOpenEcofriendly = (ecoData) => {
    handleClickOpenEco();
    if (ecoData && ecoData._id) {
      setisEcoEdit(true);
      ecoFormik.setFieldValue("_id", ecoData._id);
      ecoFormik.setFieldValue("name", ecoData.name);
      ecoFormik.setFieldValue("logo", ecoData.logo);
    } else {
      setisEcoEdit(false);
      ecoFormik.setFieldValue("name", "");
      ecoFormik.setFieldValue("_id", "");
      ecoFormik.setFieldValue("logo", "");
    }
  };

  const handleFileChangeEco = (file) => {
    const selectedFile = file.currentTarget.files[0];
    const imageUrl = URL.createObjectURL(selectedFile);
    setIsEcoImage(imageUrl);
    ecoFormik.setFieldValue("logo", selectedFile);
  };

  const deleteEcofriendlyData = async () => {
    try {
      const response = await FeatureandEcofriendlyService.deleteEcofriendly(
        deleteEco
      );
      handleClickDeleteCloseEco();
      getEcofriendlyData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "error" });
    } catch (e) {
      console.log("Error deleting eco", e);
    }
  };

  const handleClickDeleteOpenDialogEco = (data) => {
    handleClickDeleteOpenEco();
    setDeleteEco(data._id);
  };

  const columnsEco = [
    {
      name: <b>ECO-FRIENDLY</b>,
      selector: (row) => [row.name],
      sortable: true,
      cell: (row) => <div>{row.name}</div>,
    },
    {
      name: <b>LOGO</b>,
      selector: (row) => [row.name],
      sortable: true,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="demo-avatar-group my-auto float-end">
            <div className="main-img-user">
              <img
                className="rounded-circle avatar-md me-2"
                src={row.logo}
                width="30px"
                height="30px"
                alt=""
                style={{objectFit: "cover"}}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => [row.ACTIONS],
      // width: "100px",
      sortable: true,
      cell: (row) => (
        <>
          <Link
            // to={`/users/adduser/`}
            // state={{
            //   id: row.id,
            //   update: true,
            //   userData: row,
            // }}
            variant="primary"
            size="sm"
            className="me-1 btn btn-primary btn-sm text-white"
            onClick={() => handleOpenEcofriendly(row)}
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="me-1"
            onClick={() => {
              handleClickDeleteOpenDialogEco(row);
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row"></div>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={6} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div className="d-flex justify-content-between align-item-center">
                    <div>
                      <h6 className="main-content-label mb-4">
                        all Feature detail
                      </h6>
                    </div>
                    <div className="custom-card text-end pointer mb-4">
                      {" "}
                      <Link
                        className="btn btn-primary text-white"
                        onClick={handleFeatureOpen}
                      >
                        <i className="fas fa-plus"></i> &nbsp; Add Feature
                      </Link>
                      <AddFeature
                        show={open}
                        onHide={handleClickClose}
                        formik={formik}
                        isEdit={isEdit}
                        loading={loading}
                        handleFileChange={handleFileChange}
                        isFeatureImage={isFeatureImage}
                        setIsFeatureImage={setIsFeatureImage}
                      />
                    </div>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center">
                      {/* <input
                    style={{
                      borderRadius: "5px",
                      padding: "5px",
                      border: "1px solid #e8e8f7",
                    }}
                    // value={filterValue}
                    // onChange={handleFilterChange}
                    placeholder="Search here"
                    className="ms-auto mb-2 border-rounded"
                  /> */}
                    </div>
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      // style={{
                      //   fontSize: "20px",
                      //   fontWeight: "bold",
                      // }}
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                      highlightOnHover
                      //   customStyles={{
                      //     rows: {
                      //       style: customRowStyles,
                      //     },
                      //   }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div className="d-flex justify-content-between align-item-center">
                    <div>
                      <h6 className="main-content-label mb-4">
                        All Eco-Friendly Detail
                      </h6>
                    </div>
                    <div className="custom-card text-end pointer mb-4">
                      {" "}
                      <Link
                        className="btn btn-primary text-white"
                        onClick={handleOpenEcofriendly}
                      >
                        <i className="fas fa-plus"></i> &nbsp; Add Eco-Friendly
                      </Link>
                      <AddEcofriendly
                        show={openEco}
                        onHide={handleClickCloseEco}
                        formik={ecoFormik}
                        isEcoEdit={isEcoEdit}
                        loading={loadingEco}
                        isEcoImage={isEcoImage}
                        setIsEcoImage={setIsEcoImage}
                        handleFileChangeEco={handleFileChangeEco}
                      />
                    </div>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center">
                      {/* <input
                    style={{
                      borderRadius: "5px",
                      padding: "5px",
                      border: "1px solid #e8e8f7",
                    }}
                    // value={filterValue}
                    // onChange={handleFilterChange}
                    placeholder="Search here"
                    className="ms-auto mb-2 border-rounded"
                  /> */}
                    </div>
                    <DataTable
                      //   {...extensions}
                      columns={columnsEco}
                      data={ecoData}
                      noHeader
                      persistTableHead
                      pagination
                      // style={{
                      //   fontSize: "20px",
                      //   fontWeight: "bold",
                      // }}
                      paginationServer
                      paginationTotalRows={ecoTotalItem}
                      paginationPerPage={ecoPageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChangeEco}
                      onChangePage={handlePageChangeEco}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                      highlightOnHover
                      //   customStyles={{
                      //     rows: {
                      //       style: customRowStyles,
                      //     },
                      //   }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <ViewUserList show={viewOpen} onHide={handleClickViewClose} /> */}
          {/* <DeleteUserList
        show={deleteOpen}
        onHide={handleClickDeleteClose}
        path={"/users"}
        onDelete={deleteUserData}
        loading={loading}
      />
      <UserStatus show={userStatusOpen} onHide={handleStatusClose} /> */}
          <DeleteFeature
            show={deleteOpen}
            onHide={handleClickDeleteClose}
            onDelete={deleteFeatureData}
            loading={loading}
          />
          <DeleteEcofriendly
            show={DeleteOpenEco}
            onHide={handleClickDeleteCloseEco}
            onDelete={deleteEcofriendlyData}
            loading={loadingEco}
          />
        </>
      )}
    </>
  );
};

export default FeatureandEcoFriendly;
