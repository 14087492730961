import { get } from "lodash";
import serverCall from "../../serverCall";

const signIn = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  const newEmail = encodeURIComponent(email);
  const newPass = encodeURIComponent(password);

  const response = await serverCall
    .post(`/auth/admin/login?email=${email}&password=${newPass}`)
    .then((response) => {
      const data = get(response, "data", null);
      console.log("emaildata", data);
      if (data) {
        localStorage.setItem("token", get(data, "token", null));
        localStorage.setItem("email", get(data, "data.email", null));
      }
      return data;
    });
  console.log("responsesignIn", response);
  return response;
  // return (
  //   serverCall
  //     .post(``)
  //     .then((response) => {
  //       const data = get(response, "data", null);
  //       if (data) {
  //         localStorage.setItem("token", get(data, "accessToken", null));
  //         localStorage.setItem("email", get(data, "Admin.email", null));
  //       }
  //       return data;
  //     })
  // );
};

const AuthenticationService = {
  signIn,
};

export default AuthenticationService;
